//import "antd/dist/antd.css";
import 'antd/dist/antd.min.css';
import {  Suspense} from "react";
import { lazy } from '@loadable/component'
import './index.css';
//import Router from "./router";
import i18n from "./translation";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import {BarLoader} from "react-spinners";



//const i18n = lazy(() => import("./translation"));
//const I18nextProvider = lazy(() => import("react-i18next"));

const Router = lazy(() => import("./router"));


//import "@material-tailwind/react/tailwind.css";



const App = () => (
  <Suspense fallback={<BarLoader color="#36d7b7" width="100%" />}>
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <Router />
      </I18nextProvider>
    </BrowserRouter>
  </Suspense>
);

ReactDOM.render(<App />, document.getElementById("root"));
